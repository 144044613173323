//General functionality for all front end JS.
//Do not place logic in this file, it should go in /common/general.js or /common/website-section.js


//The debounce function will wrap func in a wrapper which will only call func once no matter how many times it's called.
//Useful for window resizing events, scroll events, etc.
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) {func.apply(context, args);}
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {func.apply(context, args);}
  };
}


function ajax(action, data, onSuccess, onFailure)
{
  $.ajax({
    url: gfox.ajax,
    method: 'POST',
    data: {action: action, data:data},
    success: onSuccess,
    error: onFailure
  });
}

window.$ = jQuery;
