(function ($) {
    // JS for the Accordion Section widget
	const AccordionSection = function () {



		let $allaccordions = $('.brv-accordion-section');

		$allaccordions.each(function(e){

			let $acc = $(this);
			let $head = $acc.find('.bas-head');
			let closeOthers = $acc.hasClass('close-others');

			$acc.on('openAccordion', function(e){
				console.log("Opened");
				$acc.addClass('open');
				$acc.find('.bas-content').slideDown(200);
			});

			$acc.on('closeAccordion', function(e){
				console.log("Closed");
				$acc.removeClass('open');
				$acc.find('.bas-content').slideUp(200);
			});


			$head.click(function(e){
				e.preventDefault();


				if ($acc.hasClass('open'))
				{
					$acc.trigger('closeAccordion');
				}
				else
				{
					if (closeOthers)
					{
						$allaccordions.trigger('closeAccordion');
					}
					$acc.trigger('openAccordion');
				}
			});

		});

	};

  $(window).on('theme-loaded', AccordionSection);
})(jQuery);
