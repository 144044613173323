function headerFunctionality() {
	$('header.header .mobile-menu-toggle').click(function (event) {
		event.preventDefault();
		$(this).parent('header.header').toggleClass('open');
		$(this).siblings('.menu-top-navigation-container').children('#menu-top-navigation').toggleClass('open');
	});
}

/**
 * Enables smooth scrolling for all anchors on the page.
 *
 */
function anchorSmoothScrolling() {

	var gotoAnchor = function (pathname, hostname, hash) {
		// On-page links
		if (location.pathname.replace(/^\//, '') == pathname.replace(/^\//, '') && location.hostname == hostname) {
			// Figure out element to scroll to
			var target = $(hash);
			target = target.length ? target : $('[name=' + hash.slice(1) + ']');
			// Does a scroll target exist?
			if (target.length) {

				var frmtop = $('.frame-top');
				var offset = frmtop.is(":visible") ? frmtop.height() + 16 : 16;

				$('html, body').animate({
					scrollTop: target.offset().top - offset
				}, 1000, function () {
					// Callback after animation
					// Must change focus!
					var $target = $(target);
					$target.focus();

					if ($target.is(":focus")) { // Checking if the target was focused
						return false;
					} else {
						$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
						$target.focus(); // Set focus again
					}
				});

				return true;

			}
		}

		return false;

	};


	// Select all links with hashes
	$('a[href*="#"]')
	// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.click(function (event) {

			if (gotoAnchor(this.pathname, this.hostname, this.hash)) {
				event.preventDefault();
			}

		});

	$(document).on('scrollToHash', function (e) {

		if (location.hash && location.hash.length > 1) {
			gotoAnchor(location.pathname, location.hostname, location.hash);
		}
	});

	$(document).on('scrollToAnchor', function (e, anchorname) {
		gotoAnchor(location.pathname, location.hostname, '#' + anchorname);
	});
}

$(window).on('theme-init', () => console.log('Theme init.'))
$(window).on('theme-loaded', () => console.log('Theme loaded.'))

$(window).trigger('theme-init');

$(document).ready(function(){
	
	headerFunctionality();
	anchorSmoothScrolling();
	
	$(window).trigger('theme-loaded');	
});