// JS for the Grid widget
(function ($) {
    /**
     * Function to setup the Grid and it's on click functions.
     */
    // function getQueryVariable(variable) {
    //     var query = window.location.search.substring(1);
    //     var vars = query.split('&');
    //     for (var i = 0; i < vars.length; i++) {
    //         var pair = vars[i].split('=');
    //         if (decodeURIComponent(pair[0]) == variable) {
    //             return decodeURIComponent(pair[1]);
    //         }
    //     }
    //     console.log('Query variable %s not found', variable);
    // }
    
    var remove_query = (name, url) =>  {
        return url.split( '&' ).filter( p => !p.startsWith( name + '=' ) ).join( '&' );
    }

    var gridSetup = function() {
        $('.brv-grid').each( function() {
            var $grid = $(this);
            var $gridPosts = $grid.find('.grid-posts');
            var $loader = $grid.find('.loader');
            $loader.hide();

            // LOAD MORE PAGINATION
            $grid.find('.load-more').on('click touch', function (e) {
                e.preventDefault();
                var $this = $(this);
                var instance = JSON.parse($grid.attr('data-instance'));
                var nextPage = Math.max($this.attr('data-next-page'), 1);
                $loader.show();
                $this.hide();
                
                $.post( bravepress.ajax + '?' + $.param({ paged: nextPage }), {
                    'action': 'brave_render_widget',
                    'class': 'BRV_Widget_Grid_Widget',
                    'paged': nextPage,
                    'instance': instance
                }, function(response) {
                    $gridPosts.append( $(response).find('.grid-posts article') );
                    $grid.trigger('grid-refresh');
                }).fail(function(error) {
                    console.error("ERROR: ", error);
                }).always(function() {
                    $loader.hide();
                    
                    if (nextPage < parseInt( $this.attr('data-max-pages') )) {
                        $this.show();
                    }
                });
                $this.attr('data-next-page', nextPage+1);
            });

            var $filters = $grid.find('.filter');
            $filters.each(function (i, el) {
                var $filter = $(el);
                $filter.on('click touch', function (e) {
                    e.preventDefault();
                    // Prevent page jumping
                    $grid.css('min-height', $grid.innerHeight());

                    var $this = $(this);
                    var $filterSelect = $this.parents('.filter-select');
                    var instance = JSON.parse($grid.attr('data-instance'));
                    var nextPage = Math.max($this.attr('data-next-page'), 1);
                    if ($this.hasClass('active')) {
                        $filterSelect.toggleClass('open');
                        return;
                    } else $filterSelect.removeClass('open');
                    $filter.siblings('.filter').removeClass('active');
                    $this.addClass('active');
                    if ($this.hasClass('clear')) {
                        if ($this.hasClass('clear-tax')) instance.posts = remove_query('tax_query', instance.posts);
                        else if ($this.hasClass('clear-year')) instance.posts = remove_query('year', instance.posts);
                        else if ($this.hasClass('clear-month')) instance.posts = remove_query('monthnum', instance.posts);
                    } else if ($this.attr('tax-name')) {
                        instance.posts += '&tax_query=' + $this.attr('tax-name') + ':' + $this.attr('term-name');
                    } else if ($this.attr('data-year')) {
                        instance.posts += '&year=' + $this.attr('data-year')
                    } else if ($this.attr('data-month')) {
                        instance.posts += '&monthnum=' + $this.attr('data-month')
                    }

                    

                    $grid.attr('data-instance', JSON.stringify(instance) );

                    $gridPosts.html('');
                    $loader.show();
                    $grid.find('.load-more').hide();
                    
                    $.post( bravepress.ajax + '?' + $.param({ paged: nextPage }), {
                        'action': 'brave_render_widget',
                        'class': 'BRV_Widget_Grid_Widget',
                        'paged': nextPage,
                        'instance': instance
                    }, function(response) {
                        $gridPosts.html( $(response).find('.grid-posts article') );
                        $grid.trigger('grid-refresh');
                    }).fail(function(error) {
                        console.error("ERROR: ", error);
                    }).always(function() {
                        $loader.hide();
                    });
                });
            });

        })
    };

    $(window).on('theme-init', gridSetup );  
  
  })(jQuery);
  