// JS for the Post Slider widget
(function ($) {
  /**
  * Function to initiate the Post Slider.
  */
  const PostSlider = function () {
    
    $('.brv-posts-slider').each(function(e){
      
      var $slider = $(this);
      var $prevArrow = $slider.find('.prev');
      var $nextArrow = $slider.find('.next');

      $slider.removeClass('loading');
      
      var $slickSlider = $slider.find('.brv-posts-slider-slides').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        adaptiveHeight: false,
        prevArrow: $prevArrow,
        nextArrow: $nextArrow,
        swipeToSlide: true,
        centerMode: true,
        swipe: true,
        mobileFirst: true,
        respondTo: 'slider',
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              centerMode: false
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: false
            }
          },
          {
            breakpoint: 320,
            settings: {
              centerPadding: '25px',
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true
            }
          },
        ]
      });
      
      // Fix heights to be equal
      var fixHeights = (e, slick) => {
        slick.$slides.find('article.summary').css('height', slick.$slideTrack.height() + 'px');
      }

      $slickSlider.on('init setPosition', fixHeights);
    }); 
    
  };
  
  $(window).on('theme-loaded', PostSlider);  
  
})(jQuery);
